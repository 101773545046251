let $ = require('jquery');
window.$ = window.jQuery = $;

require('hammerjs');
require('popper.js');
require('bootstrap');
require('waypoints/lib/jquery.waypoints');
require('jquery-validation');
const axios = require('axios');
const queryString = require('query-string');
const formToJson = require('form-json');
/* If needed, add other dependencies here.

   Remember:
   - Use npm or yarn to install them.
   - Require them here, like `require('bootstrap');`
   - Leave the `$ = window.$` before the requires.
*/
$ = window.$;

// CONTACT FORM
$(document).ready(function() {
  const params = queryString.parse(window.location.search);

  const utmSource = params['utm_source'];

  if (utmSource) {
    $('#contact-form').append(
      `<input type="hidden" id="utm_source" name="merge_fields.MMERGE6" value=${utmSource} />`
    );
  }

  $('#contact-form').validate({
    messages: {
      'merge_fields.MMERGE5': { required: 'This field is required' }, // Name
      email_address: {
        required: 'This field is required',
        email: 'Please enter a valid email address',
      },
      'merge_fields.MMERGE3': { required: 'This field is required' }, // Company
    },
    submitHandler: function() {
      axios
        .post(
          `VERTE_ENDPOINT_GOES_HERE`,
          formToJson($('#contact-form')[0])
        )
        .then(() => window.location.push('thankyou-page.html'))
        .catch(console.error);
    },
  });
});
// END CONTACT FORM

  autoPlayYouTubeModal();

  //FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
  function autoPlayYouTubeModal() {
      $('.btn_video').on('click touch',function () {
          var theModal = $(this).data("target"),
          videoSRC = $(this).attr("data-theVideo"),
          videoSRCauto = videoSRC + "?rel=0&amp" + "&autoplay=1" + "&showinfo=0";
          $(theModal + ' iframe').attr('src', videoSRCauto);
          $(theModal + ' button.close').click(function () {
              $(theModal + ' iframe').attr('src', '');
          });
      });
  };
            
  if(window.innerWidth > 1025) {   
    $('section .col-12').addClass('animBlock');
    $('.bg-black').addClass('animBlock'); 
    $('#brands-container .container').addClass('animBlock'); 
    $('#media-container .container').addClass('animBlock');
    $('#fulfillment .container').addClass('animBlock');

    $('#grow').waypoint(function() {
        $('#grow .container').addClass('viewed');
    }, { offset: '70%' });

    $('#fulfillment .container').waypoint(function() {
        $('#fulfillment .container').addClass('viewed');
    }, { offset: '40%' });

    $('#customer-insights .container').waypoint(function() {
        $('#customer-insights .container .col-12').addClass('viewed');
    }, { offset: '40%' });

    $('#media-container').waypoint(function() {
        $('#media-container .container').addClass('viewed');
    }, { offset: '80%' });

    $('#contact .container').waypoint(function() {
        $('#contact .container .col-12').addClass('viewed');
        $('.bg-black').addClass('viewed'); 
    }, { offset: '60%' });
    $('#butler-section .container').waypoint(function() {
        $('#butler-section .container .col-12').addClass('viewed');
    }, { offset: '60%' });
  }else{
    $('section .col-12, #grow .container, #fulfillment .container').removeClass('animBlock');
     setTimeout(function() {
       // toggle img card 1
      function toggleBrandsCard1(next) {
        var $this = $(this);
        $('#fulfillment .card-1 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#fulfillment .card-1 .image-3').hasClass('selected')) {
              $('#fulfillment .card-1 .image-1').addClass('next');
            }else{$('#fulfillment .card-1 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard1);
        next();
      }
      $('#fulfillment .card-1 div').queue(toggleBrandsCard1);
      //end toggle iimg card 1
    }, 2100);
    setTimeout(function() {
      // toggle img card 2
      function toggleBrandsCard2(next) {
        var $this = $(this);
        $('#fulfillment .card-2 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#fulfillment .card-2 .image-3').hasClass('selected')) {
              $('#fulfillment .card-2 .image-1').addClass('next');
            }else{$('#fulfillment .card-2 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard2);
        next();
      }
      $('#fulfillment .card-2 div').queue(toggleBrandsCard2);
      //end toggle iimg card 2
    }, 1900);
    setTimeout(function() {
      // toggle img card 3
      function toggleBrandsCard3(next) {
        var $this = $(this);
        $('#fulfillment .card-3 div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('#fulfillment .card-3 .image-3').hasClass('selected')) {
              $('#fulfillment .card-3 .image-1').addClass('next');
            }else{$('#fulfillment .card-3 .image-1').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        $this.queue(toggleBrandsCard3);
        next();
      }
      $('#fulfillment .card-3 div').queue(toggleBrandsCard3);
      //end toggle iimg card 3
    }, 2300);
  }

$(document).ready(function(){
    
   //hero
    setTimeout(function() {

      //bg hero toggle color
      function toggleSelected(next) {
        var $this = $(this);
        
        $('.main-content .bg-change-color-G, .main-content .bg-change-color-Y, .main-content .bg-change-color-R').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.main-content .bg-change-color-R').hasClass('selected')) {
              $('.main-content .bg-change-color-G').addClass('next');
            }else{
              $('.main-content .bg-change-color-G').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        
        $this.queue(toggleSelected);
        next();
      }

      $('.bg').queue(toggleSelected);
      //end bg hero toggle color

      //cards hero toggle 
      function toggleSelectedCard(next) {
        var $this = $(this);  
        $('.hero .block-top .img-container div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.hero .block-top .img-container div.image-1').hasClass('selected')) {

              $('.hero .block-top .img-container div.image-3').addClass('next');

            }else{

              $('.hero .block-top .img-container div.image-3').removeClass('next');

            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });   
        $this.queue(toggleSelectedCard);
        next();
      }
      $('.hero .block-top .img-container >div').queue(toggleSelectedCard);

      function toggleSelectedCard2(next) {
        var $this = $(this);  
        $('.hero .block-right .img-container div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.hero .block-right .img-container div.image-1').hasClass('selected')) {

              $('.hero .block-right .img-container div.image-3').addClass('next');

            }else{

              $('.hero .block-right .img-container div.image-3').removeClass('next');

            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });     
        $this.queue(toggleSelectedCard2);
        next();
      }
      $('.hero .block-right .img-container >div').queue(toggleSelectedCard2);
      //end cards hero toggle 

      //bg hero toggle color
      function toggleSelected22(next) {
        var $this = $(this);
        
        $('.full-img-product div').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected').next().addClass('next');
            n();
            if ($('.full-img-product div:last-child').hasClass('selected')) {
              $('.full-img-product div:first-child').addClass('next');
            }else{
              $('.full-img-product div:first-child').removeClass('next');
            }
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected').next().removeClass('next');
            n();
          });
        });
        
        $this.queue(toggleSelected22);
        next();
      }

      $('.full-img-product div').queue(toggleSelected22);
      //end bg hero toggle color
      
    }, 4000);

    setTimeout(function() { 
    //cards text toggle 
      function toggleSelectedCardText(next) {
        var $this = $(this);
        $('.hero .block-top .card-title').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText);
        next();
      }
      $('.hero .block-top .card-title').children('h6').queue(toggleSelectedCardText);

       function toggleSelectedCardText2(next) {
        var $this = $(this);
        $('.hero .block-top div .price').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText2);
        next();
      }
      $('.hero .block-top div .price').children('h6').queue(toggleSelectedCardText2);

      function toggleSelectedCardText3(next) {
        var $this = $(this);
        $('.hero .block-top div .likes').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText3);
        next();
      }
      $('.hero .block-top div .likes').children('h6').queue(toggleSelectedCardText3);
      //end card text toggle 
       //card 2 text toggle 
      function toggleSelectedCardText4(next) {
        var $this = $(this);
        $('.hero .block-right .card-title').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText4);
        next();
      }
      $('.hero .block-right .card-title').children('h6').queue(toggleSelectedCardText4);

       function toggleSelectedCardText5(next) {
        var $this = $(this);
        $('.hero .block-right div .price').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText5);
        next();
      }
      $('.hero .block-right div .price').children('h6').queue(toggleSelectedCardText5);

      function toggleSelectedCardText6(next) {
        var $this = $(this);
        $('.hero .block-right div .likes').children('h6').each(function (i, elem) {
          $this.queue(function (n) {
            $(elem).addClass('selected');
            n();     
          }).delay(2900).queue(function (n) {
            $(elem).removeClass('selected');
            n();
          });
        }).queue(toggleSelectedCardText6);
        next();
      }
      $('.hero .block-right div .likes').children('h6').queue(toggleSelectedCardText6);
      //end card 2 text toggle 
    }, 1200);
//preload
    setTimeout(function() { 
      $('body').removeClass('preload').addClass('animated');
    }, 900);
    setTimeout(function() {
      $('body').removeClass('animated');
    }, 4000);


});


// scroll to 
  function getRelatedContent(el){
    return $($(el).attr('href'));
  }
  function getRelatedNavigation(el){
    return $('.btn-scroll[href=#'+$(el).attr('id')+']');
  }

  $('.btn-scroll').on('click touch',function(e){
    e.preventDefault();
    $('html,body').animate({scrollTop:getRelatedContent(this).offset().top }, 1000);
  });